<template>
  <div class="card">
    <div class="card-body">
      <div class="fw-bold fs-5">{{ $t('__notifyRecord') }}</div>
      <div class="table-responsive">
        <table
          v-if="data && data.length"
          class="table table-borderless table-striped table-hover"
        >
          <thead>
            <tr class="text-nowrap">
              <th scope="col">{{ $t('__notifyTime') }} ：</th>
              <th scope="col">{{ $t('__notifyType') }} ：</th>
              <th scope="col">{{ $t('__notifyCause') }} ：</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="notify in data" :key="notify.id">
              <td>
                {{
                  $getTimeZoneDate(notify.time, timezone, 'YYYY-MM-DD HH:mm:ss')
                }}
              </td>
              <td>{{ notify_type_name[notify.type] }}</td>
              <td>
                {{ notify.type === 'mqttStatus' ? notify_condition_name[notify.value] : notify_condition_name[notify.type] }}
                <span class="px-2">{{
                  notify.type === 'leaveBed' || notify.type === 'mqttStatus' || notify.type === 'rrAbnormal'
                    ? ''
                    : notify.value
                }}</span>
                {{ notify_unit[notify.type] }}
              </td>
            </tr>
          </tbody>
        </table>
        <div v-else class="text-center">{{ $t('__notData') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { report } from '@/http/api/report.js'
import { mapState, mapMutations, mapGetters } from 'vuex'
import i18n from '@/lang/lang.js'

export default {
  name: 'ReportResidentNotify',
  props: ['residentID', 'startAt', 'endAt'],
  data () {
    return {
      data: null
    }
  },
  computed: {
    ...mapState(['token', 'timezone']),
    ...mapGetters(['notify_type_name', 'notify_condition_name', 'notify_unit'])
  },
  watch: {
    residentID () {
      this.getNotify()
    }
  },
  methods: {
    ...mapMutations(['Loading', 'Loaded', 'AlertsInfo', 'Alerted']),
    getNotify () {
      const vm = this
      var config = Object.assign({}, vm.token)
      config.params = {}
      config.params.started_at = vm.startAt / 1000
      config.params.ended_at = vm.endAt / 1000
      vm.Loading()
      report.getResidentNotify(vm.residentID, config).then(res => {
        if (res.status <= 201 && res.data.status === 'success') {
          vm.data = res.data.data.info
          vm.Loaded()
        } else {
          vm.Loaded()
          vm.AlertsInfo({
            state: 'error',
            title: i18n.t('__error'),
            info: res.data.errors.toString()
          })
          vm.Alerted()
        }
      })
    }
  },
  created () {},
  mounted () {
    if (this.residentID) {
      this.getNotify()
    }
  }
}
</script>

<style lang="scss"></style>
